<template>
  <div class="flex flex-col gap-8">
    <div class="flex justify-between">
      <h3 class="font-medium text-xl">QOF Data</h3>
      <button
        class="p-3 bg-teal text-white rounded-md"
        @click="
          router.push({
            name: role === 'hq' ? 'hq-add-qof-data' : 'ma-add-qof-data',
            params: { mode: 'create' },
          })
        "
      >
        + Add New Data
      </button>
    </div>
    <div class="border-2 border-gray-100 rounded-2xl bg-white">
      <div class="flex space-x-4 justify-between p-5 z-10 items-center">
        <h3 class="font-normal text-xl">Qof Data</h3>
        <DatePicker
          placeholder="Date"
          class="border rounded w-1/5"
          @selected-date="filterQofDataByDate"
          :selectedDate="selectedDate"
          @cleared-date="filterQofDataByDate"
        />
      </div>
      <QofDataTable
        :tableData="localEntries.data"
        :tableHeadings="qofDataTableHeadings"
      />
      <Pagination
        @page-changed="fetchPage"
        :currentPage="localEntries.current_page"
        :totalPages="localEntries.last_page"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import QofDataTable from "@/components/newUi/tables/QofDataTable.vue";
import Pagination from "@/components/newUi/atoms/Pagination.vue";
import DatePicker from "@/components/newUi/atoms/DatePicker.vue";

const store = useStore();
const router = useRouter();
const role = localStorage.getItem("role");
const pageNumber = ref(1);
const selectedDate = ref(null);

const qofDataTableHeadings = ref([
  "Date",
  "Indicator",
  "Sub Indicator",
  "Patient Count",
  "Missing Patient",
  "Bench Mark %",
  "Points",
  "",
]);

onMounted(async () => {
  if (role === "hq") {
    applyFiltersAndFetch({ page: 1 });
  } else if (role === "manager") {
    applyFiltersAndFetch({ page: 1 });
  }
});

const localEntries = computed(() => {
  if (role === "hq") {
    return store.getters["hqQof/getQofEntries"];
  }
  return store.getters["maQof/getQofEntries"];
});

const applyFiltersAndFetch = async (payload) => {
  try {
    if (role === "hq") {
      const response = await store.dispatch("hqQof/fetchQofEntries", payload);
      localEntries.value = response;
    } else if (role === "manager") {
      const practicePayload = {
        date: payload?.date,
        page: payload?.page,
        practice: localStorage.getItem("practice"),
      };
      await store.dispatch("maQof/fetchQofEntries", practicePayload);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const filterQofDataByDate = (date) => {
  selectedDate.value = date;
  applyFiltersAndFetch({ date: selectedDate.value, page: 1 });
};

const fetchPage = (page) => {
  if (page > localEntries.value.last_page) {
    return;
  } else {
    pageNumber.value = page;
    applyFiltersAndFetch({ page: pageNumber.value, date: selectedDate.value});
  }
};
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 8px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
}
</style>
