<template>
  <div class="relative overflow-x-auto bg-white">
    <table class="table-auto w-full text-sm text-left">
      <thead class="text-sm text-white bg-teal border-b">
        <tr>
          <th
            scope="col"
            class="px-6 py-4 tracking-wider font-medium"
            v-for="header in props.tableHeadings"
            :key="header"
          >
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="border-b" v-for="data in tableData" :key="data">
          <td class="px-6 py-4">
            <span class="py-2">{{
              moment(data?.date).format("MMM DD YYYY")
            }}</span>
          </td>
          <td class="px-8 py-4">
            {{ data?.indicator.name }}
          </td>
          <td class="px-8 py-4">
            {{ data?.sub_indicator.name }}
          </td>
          <td class="px-8 py-4">
            {{ data?.patient_count }}/{{ data?.total_patients }}
          </td>
          <td class="px-8 py-4">
            {{ data?.missing_patient }}/{{ data?.total_patients }}
          </td>
          <td class="px-8 py-4">
            {{ data?.benchmark_percentage }}%
          </td>
          <td class="px-8 py-4">
            {{ data?.achieved_points }}/{{ data?.benchmark_points }}
          </td>
          <td
            class="px-8 py-4"
            @click="
              router.push({
                name: role === 'hq' ? 'hq-add-qof-data' : 'ma-add-qof-data',
                params: { mode: 'edit', id: data.id },
              })
            "
          >
            <button class="bg-teal text-white rounded-md w-6">
              <span class="material-icons text-sm">edit</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { defineProps, toRefs } from "vue";
import { useRouter } from "vue-router";
import moment from "moment";

const router = useRouter();
const role = localStorage.getItem("role");

const props = defineProps({
  tableData: {
    type: Array,
    required: true,
  },
  tableHeadings: {
    type: Array,
    required: true,
  },
});

const { tableData } = toRefs(props);
</script>
